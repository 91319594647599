<template>
  <Qalendar :events="formattedEvents" :config="config" />
</template>

<script setup lang="ts">
import { CalendarEvent } from "@/store";
import { Qalendar } from "qalendar";
import "qalendar/dist/style.css";
import moment from "moment";
import { useStore } from '@/store'

const store = useStore()

const props = defineProps<{
  events: CalendarEvent[],
}>()

const formattedEvents = props.events.map((e) => ({
  title: store.getters.language == 'en' ? 'Booked' : 'Zarezerwowane',
  time: { start: moment(e.from).format("YYYY-MM-DD HH:mm"), end: moment(e.to).format("YYYY-MM-DD HH:mm") },
  colorScheme: "reserved",
  isEditable: false,
}));


const config = {
  isEditable: false,
  disableModes: ["week", "month"],
  style: {
    colorSchemes: {
      reserved: {
        color: '#000',
        backgroundColor: '#ffff00'
      },
    },
  },
  dayBoundaries: {
    start: 6,
    end: 18,
  },
  dayIntervals: {
    length: 60, // Length in minutes of each interval. Accepts values 15, 30 and 60 (the latter is the default)
    height: 30, // The height of each interval
  },
  week: {
    // Takes the value 'sunday' or 'monday'
    // However, if startsOn is set to 'sunday' and nDays to 5, the week displayed will be Monday - Friday
    startsOn: 'monday',
    // Takes the values 5 or 7.
    nDays: 7,
    // Scroll to a certain hour on mounting a week. Takes any value from 0 to 23.
    // This option is not compatible with the 'dayBoundaries'-option, and will simply be ignored if custom day boundaries are set.
    scrollToHour: 5,
  },
  month: {
    // Hide leading and trailing dates in the month view (defaults to true when not set)
    showTrailingAndLeadingDates: false,
  },
  // Takes any valid locale that the browser understands. However, not all locales have been thorougly tested in Qalendar
  // If no locale is set, the preferred browser locale will be used
  locale: store.getters.language === 'en' ? 'en-US' : 'pl-PL',
  // if not set, the mode defaults to 'week'. The three available options are 'month', 'week' and 'day'
  // Please note, that only day and month modes are available for the calendar in mobile-sized wrappers (~700px wide or less, depending on your root font-size)
  defaultMode: 'day',
  // The silent flag can be added, to disable the development warnings. This will also bring a slight performance boost
  isSilent: true,
  showCurrentTime: true,
};
</script>

<style scoped lang="scss">
.calendar-root-wrapper {
  min-height: 200px;
}
</style>

