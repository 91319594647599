<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" class="py-0" md="6" no-gutters justify="center">
        <p class="text-h7 text--primary">
          Od
        </p>
      </v-col>
      <v-col cols="12" class="py-0" md="6" no-gutters justify="center">
        <p class="text-h7 text--primary">
          Do
        </p>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="pa-2" md="6" no-gutters>
        <Datepicker v-model="dateFrom" auto-apply :startTime="{hours: 8, minutes: 0}" :minDate="new Date()" cancelText="Zamknij"
          :enableTimePicker="props.placeType.startsWith('hotdesk') ? false : true" :format-locale="pl" lang="pl" format="dd/MM/yyyy HH:mm"
          minutesIncrement="30" noMinutesOverlay
          @update:modelValue="handleDateToFilled" required selectText="Wybierz">
        </Datepicker>

      </v-col>
      <v-col cols="12" class="pa-2" md="6">
        <Datepicker v-model="dateTo" auto-apply :minDate="new Date()" cancelText="Zamknij" @update:modelValue="handleDateToFilled"
          minutesIncrement="30" noMinutesOverlay :startTime="{hours: 16, minutes: 0}" :format-locale="pl" lang="pl" format="dd/MM/yyyy HH:mm"
          :enableTimePicker="props.placeType.startsWith('hotdesk') ? false : true" required selectText="Wybierz">
        </Datepicker>
      </v-col>
    </v-row>

  </div>
</template>

<script setup lang="ts">
import Datepicker from '@vuepic/vue-datepicker';
import { ref } from 'vue';
import { pl } from 'date-fns/locale';
import { useStore } from '@/store'
import '@vuepic/vue-datepicker/dist/main.css'


const store = useStore()

const props = defineProps<{
  placeType: string
}>()

const dateFrom = ref();
const dateTo = ref();
const emit = defineEmits(['timeFilled'])
const lang = store.getters.language;

const translations :any = {
  "pl": {
    "errors":
    {
      "dateToNotBeforeFrom": "Data od nie może być wcześniejsza niż data do",
      "minimalReservation": "Minimalna rezerwacja sali to 1 godzina"
    }
  },
  "en": {
    "errors":
    {
      "dateToNotBeforeFrom": "The 'to' date cannot be earlier than the 'from' date",
      "minimalReservation": "The minimum room reservation is 1 hour"
    }
  }
}

const handleDateToFilled = (value: Date) => {
  if (!dateFrom.value || !dateTo.value) {
    return false;
  }

  if (dateFrom.value > value && value) {
    alert(translations[lang]['errors']['dateToNotBeforeFrom']);
    dateTo.value = null;
    return false;
  }

  // if (Math.floor(((dateTo.value - dateFrom.value) / 1000) / 60) < 60) {
  //   alert(translations[lang]['errors']['minimalReservation']);
  //   dateTo.value = null;
  //   return false;
  // }

  if (dateTo.value < dateFrom.value) {
    dateTo.value = "";
    return false;
  }
  emit('timeFilled', { "from": dateFrom.value, "to": dateTo.value });
  return true;
}

</script>

<style lang="scss">
$dp__font_size: 0.7rem !default;

@import '@../../../../node_modules/@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss';
</style>

