<script setup lang="ts">
import PlaceToBook from '@/components/PlaceToBook.vue'
import PlaceCategory from '@/components/PlaceCategory.vue'
import { onBeforeMount } from "vue"
import { ref } from '@vue/reactivity'
import lodash from 'lodash'
import { useStore, BookingPlace } from '@/store'

const store = useStore()
const loading = ref(true);
const bookingPlaces = ref<BookingPlace[]>()

const props = defineProps<{
  lang: string
}>()

onBeforeMount(async () => {
  await store.commit("SET_LANGUAGE", props.lang ?? 'pl');
  await store.dispatch('loadBookingPlaces');
  await store.dispatch('loadCalendarEvents');

  bookingPlaces.value = store.getters.bookingPlaces.filter(p => !p.Typ.startsWith('hotdesk'));
  loading.value = false;
 });


</script>
<template>
  <v-app>  
        <div class="text-center" v-if="loading">
          <v-progress-circular :size="100" color="primary" indeterminate>
          </v-progress-circular>
        </div>
        <v-row justify="center" no-gutters v-if="!loading">
          <v-col class="primary lighten-2 py-4 text-center white--text" v-bind:key="row[0].Id" v-for="row in lodash.groupBy(bookingPlaces, 'Typ')" sm="12"
            md="6">
            <PlaceToBook :title="store.getters.language == 'en'? row[0]['Nazwa EN'] : row[0].Nazwa" :id="row[0].Id" :type="row[0].Typ" :img="row[0]['Link do zdjęcia']">
            </PlaceToBook>
          </v-col>
          <v-col class="primary lighten-2 py-4 text-center white--text" sm="12"
            md="6">
            <PlaceCategory v-if="store.getters.bookingPlaces.some(p => p.Typ.startsWith('hotdesk'))" title="Hotdesk" :img="store.getters.bookingPlaces.filter(p => p.Typ.startsWith('hotdesk'))[0]['Link do zdjęcia']">
            </PlaceCategory>
          </v-col>
        </v-row>
  </v-app>
</template>

