<template>
  <form @submit.prevent="submitForm">

    <v-card class="mx-auto my-12" max-width="374">
      <template>
        <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
      </template>
      <img height="250" :src="img" v-bind:style="unavailableImgDecorator" />
      <events-calendar-list v-if="!props.type.startsWith('hotdesk')" :events="getCalendarEvents(id)"></events-calendar-list>
      <v-card-title>{{ props.title }}
      </v-card-title>
      <v-alert color="red" class="ma-5" dense outlined v-if="isReservationDisabled" type="warning">
        {{store.getters.language == 'en' ? 'Slot not available' : 'Termin niedostępny'}}
      </v-alert>
      <v-divider class="mx-4"></v-divider>

      <v-card-text>
        <BookingPanel @time-filled="onUpdateDates" :place-type="props.type"></BookingPanel>
      </v-card-text>

      <v-footer color="primary lighten-1" padless>
        <v-btn block color="transparent" v-bind:disabled="isReservationDisabled" type="submit">
           {{store.getters.language == 'en' ? 'Reserve' : 'Rezerwuj'}}
        </v-btn>
      </v-footer>

    </v-card>
  </form>

</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import BookingPanel from '../components/BookingPanel.vue'
import EventsCalendarList from '@/components/EventsCalendarList.vue'
import { useRouter } from 'vue-router';
import { isPlaceAvailable } from '@/modules/placeAvailibiltiyChecker'
import { getCalendarEvents } from '@/modules/eventsFilter'
import { CalendarEvent } from '@/store';
import { useStore } from '@/store'

const store = useStore()

const router = useRouter();

const props = defineProps<{
  title: string,
  id: string,
  type: string,
  img: string
}>()


const isReservationDisabled = ref(false);
const unavailableImgDecorator = computed(() => isReservationDisabled.value ? "filter: grayscale(0.8);" : "");
const dateFrom = ref(new Date());
const dateTo = ref(new Date());

const onUpdateDates = async (event: CalendarEvent) => {
  console.log(event.from)
  console.log(event.to)
  console.log(`Is place available ${isPlaceAvailable(props.type, event.from, event.to)}`)
  if (!isPlaceAvailable(props.type, event.from, event.to)) {
    isReservationDisabled.value = true;
    return;
  }

  dateFrom.value = event.from;
  dateTo.value = event.to;
  isReservationDisabled.value = false;
}

const submitForm = () => {
  if (isReservationDisabled.value) {
    return;
  }
  router.push({
    path: '/about',
    query: {
      from: dateFrom.value.toISOString(),
      to: dateTo.value.toISOString(),
      type: props.type,
      id: props.id,
      name: props.title
    }
  })
}

</script>
