import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import * as Vue from 'vue';
import axios from 'axios'
import VueAxios from 'vue-axios'
import {store} from '@/store'

loadFonts();
const app = createApp(App).use(store);

app
  .use(router)
  .use(VueAxios, axios)
  .provide("axios", app.config.globalProperties.axios)
  .use(vuetify)
  .mount("#app");
