<template>
  <form @submit.prevent="submitForm">

    <v-card class="mx-auto my-12" max-width="374">
      <template>
        <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
      </template>
      <img height="250" :src="img"  />

      <v-card-title>{{ props.title }}
      </v-card-title>
      <v-divider class="mx-4"></v-divider>
      <v-footer color="primary lighten-1" padless>
        <v-btn block color="transparent" type="submit">
          Zobacz więcej
        </v-btn>
      </v-footer>

    </v-card>
  </form>

</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';

const router = useRouter();

const props = defineProps<{
  title: string,
  img: string
}>()

const submitForm = () => {
  router.push({
    path: '/hotdesk-list'})
}

</script>
